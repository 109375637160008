@import url('https://fonts.googleapis.com/css2?family=Merienda&display=swap');

body {
  margin: 0;
  padding: 0;
  font-size: 20px;
}

.MuiTypography-h6 {
  font-family: 'Merienda', cursive !important;
}

.header {
  overflow: hidden;
  background-color: white;
  color: black;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 12;
  font-size: 35px;
  text-align: center;
  font-weight: bold;
  padding-bottom: 5px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

ul {
  padding-inline-start: 20px !important;
}

.header:hover {
  cursor: pointer;
}

.reportTodayButton {
  position: fixed !important;
  bottom: 6px;
  z-index: 1000;
  background-color: #F89938 !important;
  color: white !important;
  width: 50%;
}

.search {
  position: absolute;
  top: 5.5rem;
  left: 50%;
  width: 500px;
  max-width: 400px;
  z-index: 10;
  transform: translateX(-50%);
  background: white;
}

.collapseAlert {
  position: fixed;
  bottom: 0px;
  width: 100%;
  margin: auto;
  height: 0px;
  transition-duration: 300ms;
}

.dialogSection {
  margin: 10px;
  text-align: center;
}

.shareButtons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swipeable-list {
  border: 1px solid;
  border-radius: 20px;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.swipeable-list-item__content-left {
  background: red;
  border-radius: 20px;
}

.swipeable-list-item__content-right {
  background: green;
  border-radius: 20px;
}

.swipeMainItem {
  cursor: pointer;
  height: 50px !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.safeArrow {
  height: 50px !important;
  width: 50px !important;
  color: green;
}

.unSafeArrow {
  height: 50px !important;
  width: 50px !important;
  color: red;
}

.swiperImage {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  border: 1px solid;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.reportTodayDialogCon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10%;
}

.firstPageCard {
  background-color: #F89938 !important;
  color: white !important;
  text-align: center !important;
}

.textContainer {
  width: 20%;
  margin: 20px !important;
}

.barometerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sliderText {
  position: absolute;
  z-index: 1;
}

.sliderTextSafe {
  right: 32%;
}

.sliderTextUnsafe {
  left: 32%;
}

/* barometer css */
.layout-align {
  margin: 20px 0px 40px 0px;
}
.score-meter-1 {
  width: 300px;
  height: 150px;
  border-top-left-radius: 360px;
  border-top-right-radius: 360px;
  overflow: hidden;
  position: relative;
}
.scorer-1-inner-div {
  position: absolute;
  left: 20%;
  top: 40%;
  width: 60%;
  height: 60%;
  border-top-left-radius: 360px;
  border-top-right-radius: 360px;
  background-color: #ffffff;
  z-index: 2;
}
.scorer-1-inner-div-2 {
  position: absolute;
  left: 0%;
  top: 0%;
  z-index: 4;
  width: 100%;
  height: 100%;
  border-top-left-radius: 360px;
  border-top-right-radius: 360px;
  background-color: red;
  transform-origin: bottom center;
  transform: rotate(-130deg);
  z-index: 0;
}
.scorer-1-inner-div-3 {
  position: absolute;
  right: 0%;
  top: 0%;
  z-index: 4;
  width: 100%;
  height: 100%;
  border-top-left-radius: 360px;
  border-top-right-radius: 360px;
  background-color: #a3cd3b;
  transform-origin: bottom center;
  transform: rotate(130deg);
  z-index: 0;
}
.scorer-1-inner-div-4 {
  position: absolute;
  left: 50px;
  top: -2px;
  width: 0;
  height: 0;
  border-left: 100px solid transparent;
  border-right: 100px solid transparent;
  border-bottom: 140px solid #ffca3d;
  transform: rotate(180deg);
}
.scorer-1-tick {
  position: absolute;
  top: 40%;
  left: -250%;
  width: 300%;
  height: 5px;
  background-color: #000000;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-right-radius: 5%;
  border-bottom-right-radius: 5%;
  animation-fill-mode: forwards;
  transform-origin: right center;
}
.scorer-1-inner-div-5 {
  position: absolute;
  left: 45%;
  top: 80%;
  width: 10%;
  height: 20%;
  border-radius: 50%;
  background-color: #000000;
  z-index: 2;
}

/* Slider Button Css */
/* .slidecontainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  -webkit-appearance: none;
  width: 50%;
  height: 40px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: auto;
  appearance: none;
  width: 53px;
  height: 54px;
  border: 0;
  background-image: url('logo.png');
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 23px;
  height: 24px;
  border: 0;
  background: #000000;
  background: url('logo.png');
  cursor: pointer;
} */

@media only screen and (max-width: 800px) {
  .search {
    top: 5.5rem;
    width: 300px;
  }
  .header {
    font-size: 25px;
  }
  .reportTodayDialogCon {
    padding: 25% 5% 10% 5%;
    font-size: 15px;
  }
  .layout-align {
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0 25px 0;
  }
  .textContainer {
    width: 100%;
    margin: 20px !important;
  }
  .slider {
    width: 100%;
  }

  .sliderTextSafe {
    right: 8%;
  }

  .sliderTextUnsafe {
    left: 8%;
  }

  .reportTodayButton {
    bottom: 10px;
    width: 95%;
  }

  .slidecontainer {
    margin-left: 10px;
  }

  .safeArrow {
    font-size: 16vw;
  }

  .unSafeArrow {
    font-size: 16vw;
  }

  .dialogSection {
    font-size: 15px;
  }
}

@media only screen and (max-width: 321px) {
  .search {
    top: 5.5rem;
    width: 250px;
  }
  .header {
    font-size: 20px;
  }
  .reportTodayDialogCon {
    padding: 30% 5% 10% 5%;
    font-size: 15px;
  }
  .layout-align {
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 10px 0;
    transform: scale(0.8);
  }
  .textContainer {
    width: 100%;
    margin: 20px !important;
  }
  .slider {
    width: 100%;
  }
  .sliderTextSafe {
    right: 12%;
  }

  .sliderTextUnsafe {
    left: 12%;
  }

  .reportTodayButton {
    bottom: 10px;
    width: 95%;
  }

  .safeArrow {
    font-size: 12vw;
  }

  .unSafeArrow {
    font-size: 12vw;
  }

  .dialogSection {
    font-size: 15px;
  }
}
